import React from 'react'
import FakultetlarCom from '../../../components/FakultetlarCom'

const Fakultetlar = () => {
  return (
    <div>
        <FakultetlarCom />
    </div>
  )
}

export default Fakultetlar