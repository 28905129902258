import React from 'react'
import IlmiyFaolyatCom from '../../../components/IlmiyFaoliyatCom'

function IlmiyFaolyaat() {
    return (
        <div>
            <IlmiyFaolyatCom />
        </div>
    )
}

export default IlmiyFaolyaat