import React from 'react'
import OquvUslubiyFaolyatCom from '../../../components/OquvUslubiyFaolyatCom'

function OquvUslubiyFaolyat() {
    return (
        <div>
            <OquvUslubiyFaolyatCom />
        </div>
    )
}

export default OquvUslubiyFaolyat