import React from "react";
import OquvDasturlariCom from "../../../../components/OquvRejalariCom";

function OquvRejalari() {
  return (
    <div>
      <OquvDasturlariCom />
    </div>
  );
}

export default OquvRejalari;
