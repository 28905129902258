import React from "react";
import BarchaElonlarCom from "../../components/BarchaElonlarCom";

function BarchaElonlar() {
  return (
    <div>
      <BarchaElonlarCom />
    </div>
  );
}

export default BarchaElonlar;
