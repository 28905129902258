import React from 'react'
import AdminQabulxona from '../../components/AdminKorrupsiyaQabulxona'

const AdminKorrupsiyaQabulxonaPage = () => {
  return (
    <div>
        <AdminQabulxona />
    </div>
  )
}

export default AdminKorrupsiyaQabulxonaPage