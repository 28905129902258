import React from 'react'
import AdminQabulxona from '../../components/AdminQabulxona'

const AdminQabulxonaPage = () => {
  return (
    <div>
        <AdminQabulxona />
    </div>
  )
}

export default AdminQabulxonaPage