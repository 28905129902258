import React from 'react'
import YoshlarBnIshlashMvaMCom from '../../../components/YoshlarBnIshlashMvaMCom'

function YoshlarBnIshlashMvaM() {
    return (
        <div>
            <YoshlarBnIshlashMvaMCom />
        </div>
    )
}

export default YoshlarBnIshlashMvaM