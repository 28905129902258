import React from 'react'
import XalqaroElonlar from '../../../../components/XalqaroElonlar'

const XalqaroElonlarPage = () => {
  return (
    <div>
        <XalqaroElonlar />
    </div>
  )
}

export default XalqaroElonlarPage