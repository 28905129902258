import React from 'react'
import XalqaroHamkorlar from '../../../../components/XalqaroHamkorlar'

const XalqaroHamkorlarPage = () => {
  return (
    <div>
        <XalqaroHamkorlar />
    </div>
  )
}

export default XalqaroHamkorlarPage