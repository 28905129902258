import React from 'react'
import AbiturientCallCom from "../../../components/AbiturientCallCom"


function CallMarkaz() {
  return (
    <div>
      <AbiturientCallCom />
    </div>
  )
}

export default CallMarkaz