import React from "react";
import VideoMaruzalarCom from "../../components/VideoMaruzaCom";

const VideoMaruzalarPage = () => {
  return (
    <div>
      <VideoMaruzalarCom />
    </div>
  );
};

export default VideoMaruzalarPage;
