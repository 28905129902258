import React from 'react'
import ProfessorFikr from '../../../../components/XalqaroProfessorFikr'

const ProfessorlarFikri = () => {
  return (
    <div>
        <ProfessorFikr />
    </div>
  )
}

export default ProfessorlarFikri