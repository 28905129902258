import React from 'react'
import DTSvaMalakaCom from '../../../../components/DTSvaMalakaCom'

function DTSvaMalaka() {
  return (
    <div>
        <DTSvaMalakaCom/>
    </div>
  )
}

export default DTSvaMalaka