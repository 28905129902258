import React from 'react'
import OchiqMalumotlarCom from '../../components/OchiqMalumotlarCom'

function OchiqMalumotlar() {
  return (
    <div>
        <OchiqMalumotlarCom/>
    </div>
  )
}

export default OchiqMalumotlar