import React from "react";
import MagistrOquvRejalariCom from "../../../../components/MagistrOquvRejalariCom";

function OquvRejalari() {
  return (
    <div>
      <MagistrOquvRejalariCom/>
    </div>
  );
}

export default OquvRejalari;
