import React from 'react'
import KafedralarCom from '../../../components/KafedralarCom'

const Kafedralar = () => {
  return (
    <div>
        <KafedralarCom />
    </div>
  )
}

export default Kafedralar;