import React from 'react'
import MagistrFanKatalogiCom from '../../../../components/MagistrFanKatalogiCom'

function MagistrFanKatalogi() {
  return (
    <div>
        <MagistrFanKatalogiCom/>
    </div>
  )
}

export default MagistrFanKatalogi