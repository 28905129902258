import React from 'react'
import MagistrDTSvaMalkaCom from '../../../../components/MagistrDTSvaMalakaCom'

function MagistrDTSvaMalaka() {
  return (
    <div>
        <MagistrDTSvaMalkaCom/>
    </div>
  )
}

export default MagistrDTSvaMalaka