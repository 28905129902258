import React from 'react'
import LoginKorrupsiya from "../../components/LoginKorrupsiya";

const LoginKorrupsiyaPage = () => {
  return (
    <div>
        <LoginKorrupsiya />
    </div>
  )
}

export default LoginKorrupsiyaPage