import React from "react";
import MagistraturaCom from "../../../components/MagistratureCom";

function Magistratura() {
  return (
    <div>
      <MagistraturaCom />
    </div>
  );
}

export default Magistratura;
