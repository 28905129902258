import React from 'react'
import BolimlarCom from '../../../components/BolimlarCom'


const Bolimlar = () => {
  return (
    <div>
        <BolimlarCom />
    </div>
  )
}

export default Bolimlar