import React from 'react'
import RekvizitlarCom from '../../components/RekvizitlarCom'


const Rekvizitlar = () => {
  return (
    <div>
        <RekvizitlarCom />
    </div>
  )
}

export default Rekvizitlar