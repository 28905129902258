import React from "react";
import QabulKvotasi from "../../../../components/QabulKvota";

const QabulKvota = () => {
  return (
    <div>
      <QabulKvotasi />
    </div>
  );
};

export default QabulKvota;
