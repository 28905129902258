import React from "react";
import MarkazlarCom from "../../../components/MarkazlarCom";

const Markazlar = () => {
  return (
    <div>
      <MarkazlarCom />
    </div>
  );
};

export default Markazlar;
