import React from 'react'
import AbiturientXorijiyTalabaCom from "../../../components/AbiturientXorijiyTalabaCom"

function HorijiyTalabalarQabul() {
  return (
    <div>
      <AbiturientXorijiyTalabaCom />
    </div>
  )
}

export default HorijiyTalabalarQabul;