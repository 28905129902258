import React from 'react'
import XalqaroYangiliklar from '../../../../components/XalqaroYangiliklar';

const XalqaroYangiliklarPage = () => {
  return (
    <div>
      <XalqaroYangiliklar />
    </div>
  )
}

export default XalqaroYangiliklarPage;